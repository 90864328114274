@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@import "vars.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    overflow: hidden;
}

.container {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background: $background-color;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        background: $background-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $red-1;
    }
}

.page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background: $background-color;
    position: absolute;
}

@import "header/header.scss";

@import "forms.scss";

@import "../pages/pages.scss";

.nav-link {
    color: $white-1;
    text-decoration: none;
    cursor: pointer;
    transition: 100ms ease all;
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -2px;
        transform: scaleX(0);
        transform-origin: left;
        background: $white-1;
        position: absolute;
        transition: 100ms ease all;
    }

    &:hover {
        color: $pink-1;

        &::after {
            transform: scaleX(1);
            background: $pink-1;
        }
    }
}

.back-button {
    padding: 10px 20px;
    border: none;
    outline: none;
    left: 0;
    top: 0;
    margin: 10px;
    background: $purple-4;
    border-radius: 10px;
    cursor: pointer;
    color: $white-2;
    z-index: 99;
    position: fixed;
}

.home-button {
    padding: 10px 20px;
    border: none;
    outline: none;
    left: 80px;
    top: 0;
    margin: 10px;
    background: $purple-4;
    border-radius: 10px;
    cursor: pointer;
    color: $white-2;
    z-index: 99;
    position: fixed;
}


.color_white {
    color: $white-1;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_column {
    flex-direction: column;
}