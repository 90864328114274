$background: $background-color;
$link-background: rgb(20,20,20);
.disabled {
    opacity: 0.2;
    pointer-events: none;
}
.navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background: $background;
    position: absolute;
    .navigate-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white-1;
        width: 90%;
        height: auto;
        margin: 0;
        position: relative;
        .wrapper__links {
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 30px;
            width: 90%;
            height: 90%;
            position: relative;
            .links__link {
                display: flex;
                align-items: center;
                font-size: 2em;
                min-width: 200px;
                width: 40%;
                height: 100px;
                padding: 20px 5% 20px 5%;
                border-radius: 20px;
                text-decoration: none;
                color: $white-1;
                background: $link-background;
                position: relative;
                transition: 100ms ease all;
                outline: 1px solid transparent;
                &:hover {
                    outline: 1px solid $blue-4;
                    p {
                        left: 30%;
                    }
                    .icon {
                        opacity: 1;
                        margin: 20px;
                    }
                }
                &:active {
                    color: $blue-2;
                }
                p {
                    left: 50%;
                    top: 15%;
                    transform: translate(-50%,-50%);
                    position: absolute;
                    transition: 100ms ease all;
                }
                .icon {
                    opacity: 0;
                    font-variation-settings:
                    'FILL' 1,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
                    font-size: 1em;
                    color: $blue-4;
                    right: 10%;
                    transition: 100ms ease all;
                    position: absolute;
                }
                .arrow {
                    display: none;
                }
            }
            .links__login {
                display: block;
                font-size: 2em;
                width: auto;
                height: 100px;
                padding: 20px 10% 20px 10%;
                border-radius: 20px;
                text-decoration: none;
                color: $white-1;
                background: $link-background;
                position: relative;
                transition: 100ms ease all;
                outline: 1px solid transparent;
                max-width: 200px;
                &:hover {
                    outline: 1px solid $blue-4;
                }
                p {
                    left: 50%;
                    top: 15%;
                    transform: translate(-50%,-50%);
                    position: absolute;
                    transition: 100ms ease all;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .navigate {
        .navigate-wrapper {
            .wrapper__links {
                flex-wrap: nowrap;
                flex-direction: column;
                .links__link {
                    width: 90%;
                    &:hover {
                        outline: 1px solid $blue-2;
                        p {
                            left: 50%;
                        }
                        .arrow {
                            opacity: 1;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .icon {
                        display: none;
                    }
                    .arrow {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        width: 100%;
                        height: 30px;
                        opacity: 0;
                        font-size: 1.5em;
                        left: 50%;
                        top: 0;
                        transform: translate(-50%, 20%);
                        display: block;
                        outline: 1px solid $blue-2;
                        background: $link-background;
                        border-radius: 20px 20px 0 0;
                        position: absolute;
                        transition: 100ms ease all;
                        span {
                            font-size: 1em;
                            top: -12.5px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}