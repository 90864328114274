.home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // height: calc(100% - $header-height);
    left: 0;
    // background: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("../../../assets/images/backgrounds/pexels-instawalli-176851.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // top: $header-height;
    top: 0;
    position: absolute;
    .home__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white-1;
        width: 90%;
        height: auto;
        margin: 0;
        position: relative;
        .wrapper__title {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            height: 100%;
            padding: 20px;
            position: relative;
            .title__h1 {
                font-family: 'Poiret One', sans-serif;
                font-size: 5em;
                font-weight: 100;
                padding: 0;
                margin: 0;
            }
            .title__h2 {
                font-family: 'Audiowide', cursive;
                font-size: 4em;
            }
        }
        .wrapper__continue {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            height: 100%;
            padding: 20px;
            .continue__button {
                width: 70px;
                height: 70px;
                // border-radius: 50px 10px 50px 50px;\
                border-radius: 50%;
                outline: 0;
                border: 0;
                background: transparent;
                position: relative;
                cursor: pointer;
                transition:0.5s;
            }
            .center-con {
                display: flex;
                align-items: center;
                justify-content: center;
                .round {
                    position: absolute;
                    border: 2px solid #fff;
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    #cta{
                        background: $green-1;
                        width:100%;
                        cursor: pointer;
                        position: absolute;
                        .arrow {
                            left: 25%;
                        }
                    }
                    .arrow {
                        position: absolute;
                        bottom: 0;
                        width: 30px;
                        height: 30px;
                        background-size: contain;
                        top: 17.5px;
                    }
                    .segunda {
                        margin-left: 15px;
                    }
                    .next {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
                    }                    
                }
            }
        }
    }
}
@keyframes bounceAlpha {
    0% {opacity: 1; transform: translateX(0px) scale(1);}
    25%{opacity: 0; transform:translateX(10px) scale(0.9);}
    26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
    55% {opacity: 1; transform: translateX(0px) scale(1);}
  }
  
  .bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration:1.4s;
      animation-iteration-count:infinite;
      animation-timing-function:linear;
  }
  
  .arrow.primera.bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration:1.4s;
      animation-delay:0.2s;
      animation-iteration-count:infinite;
      animation-timing-function:linear;
  }
  
  .round:hover .arrow{
      animation-name: bounceAlpha;
      animation-duration:1.4s;
      animation-iteration-count:infinite;
      animation-timing-function:linear;
  }
  .round:hover .arrow.primera{
      animation-name: bounceAlpha;
      animation-duration:1.4s;
      animation-delay:0.2s;
      animation-iteration-count:infinite;
      animation-timing-function:linear;
  }