@import "./sub/stats.scss";
.projects {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(10, 10, 10);
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 10px;
        background: $background-color;
    }
    &::-webkit-scrollbar-thumb {
        background: $red-1;
    }

    .projects__wrapper {
        display: flex;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .wrapper__section-intro {
            display: flex;
            align-items: center;
            justify-content: top;
            flex-direction: column;
            width: 100vw;
            height: 300px;
            padding: 0;
            margin: 0;
            background: url("../../../assets/images/backgrounds/pexels-instawalli-176851.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            z-index: 20;
            overflow: hidden;

            //             --mask:
            //     radial-gradient(22.36em at 50% calc(100% - 30.00em),#000 99%,#0000 101%) calc(50% - 20em) 0/40em 100%,
            //     radial-gradient(22.36em at 50% calc(100% + 20.00em),#0000 99%,#000 101%) 50% calc(100% - 10em)/40em 100% repeat-x;
            //   -webkit-mask: var(--mask);
            //           mask: var(--mask);
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 3;
                background: linear-gradient(0deg, rgba(10, 10, 10, 1) 1%, rgba(0, 0, 0, 0.24693627450980393) 100%);
                position: absolute;
            }

            img {
                width: 40vw;
                height: auto;
                position: absolute;
                z-index: 1;
                animation: rotateMoon 200s linear infinite;
                filter: brightness(90%);
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
            }
        }

        .wrapper__section-title {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100vw;
            height: 400px;
            top: 10em;
            margin: 0 0 5% 0;
            position: relative;

            .section__text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 50px 0 0 0;
                z-index: 2;

                h1 {
                    font-size: 3em;
                    // -webkit-text-stroke-width: 1px;
                    // -webkit-text-stroke-color: black;
                    color: white;
                    padding: 0;
                    margin: 0;
                }

                p {
                    font-size: 1.2em;
                    color: white;
                }
            }
        }

        .wrapper__section-projects {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            padding: 20px;
            position: relative;

            #search {
                width: 80%;
                padding: 10px;
                outline: none;
                border: none;
                background: $white-1;
                border-radius: 5px;
                position: relative;
            }

            .section__table {
                width: 100%;
                margin-top: 2.5%;
                border: none;
                border-spacing: 0 10px;

                .table__project {
                    width: 100%;
                    background: rgb(35, 35, 35);
                    border: none;

                    td {
                        color: $white-2;
                        // width: calc(100px / 3);
                        padding: 20px;
                        border: none;
                        text-align: center;
                        position: relative;

                        .project__link {
                            text-decoration: none;
                            color: $white-2;
                            background: $purple-4;
                            padding: 5px 10px;
                            border-radius: 20px;
                        }

                        #status {
                            color: $white-1;
                            text-decoration: none;
                            pointer-events: none;
                            // background: $orange-4;
                            // padding: 10px 20px;
                            // border-radius: 10px;
                        }

                        span {
                            margin-left: 10px;
                        }

                        .status {
                            background: $grey-1;
                            width: 20px;
                            height: 20px;
                            top: 50%;
                            transform: translate(0, -50%);
                            border-radius: 50%;
                            position: absolute;
                        }

                        .green {
                            background: $green-4;
                        }

                        .yellow {
                            background: $yellow-1;
                        }

                        .red {
                            background: $red-4;
                        }
                    }
                }
            }
        }
    }
}

@keyframes rotateMoon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
