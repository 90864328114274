.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  .auth {
    color: #dcdcdc;
    width: 90%;
    max-width: 400px;
    border: 1px solid #dcdcdc;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    // background: rgb(25,25,25);
    background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url("../../assets/images/logos/DarkSideWeb.png");
    background-position: center;
    background-size: 40em;
    background-repeat: no-repeat;
    h1 {
      font-size: 2em;
      color: #dcdcdc;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0 auto 10px auto;
  
      input,
      button {
        height: 40px;
        font-size: 16px;
        padding: 0 10px;
      }
      button {
        outline: 0;
        cursor: pointer;
      }
    }
    a {
      text-align: center;
      text-decoration: none;
      color: $purple-4;
      transition: 100ms ease all;
      background: $white-1;
      padding: 0 10px 2.5px 10px;
      border-radius: 50px;
      &:hover {
        color: $purple-6;
      }
    }
  }
  
  .auth__success {
    padding: 10px 0;
    background-color: hsl(147, 79%, 87%);
    margin-bottom: 20px;
  }
  
  .auth__error {
    padding: 10px 0;
    background-color: hsl(0, 79%, 87%);
    margin-bottom: 20px;
  }
}