// $variableName: value;

$black-1: #0F1A20;
$black-2: #11151C;
$black-3: #291F1E;
$blue-1: #223843;
$blue-2: #2892D7;
$blue-3: #173753;
$blue-4: #5386E4;
$blue-5: #2E4052;
$blue-5: #357DED;
$blue-6: #2667FF;
$green-1: #50FFB1;
$green-2: #4D685A;
$green-3: #32E875;
$green-4: #6BF178;
$grey-1: #333333;
$grey-2: #5E5E5E;
$grey-3: #605F5E;
$grey_blue-1: #212D40;
$grey_blue-2: #364156;
$orange-1: #D77A61;
$orange-2: #D66853;
$orange-3: #7D4E57;
$orange-4: #FA824C;
$pink-1: #EF476F;
$purle-9: #E5ECF4;
$purple-1: #4C4B63;
$purple-2: #1F2041;
$purple-3: #1C1D38;
$purple-4: #5438DC;
$purple-5: #826AED;
$purple-6: #3B0086;
$purple-7: #8A4FFF;
$purple-8: #C3BEF7;
$red-1: #D62839;
$red-2: #F42C04;
$red-3: #FF5964;
$red-4: #FB3640;
$red-5: #FF0035;
$white-1: #EFF1F3;
$white-2: #F7F0F0;
$yellow-1: #FFC857;

$background-color: rgb(25,25,25);

$header-height: 75px;