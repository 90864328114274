header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: $header-height;
    padding: 0;
    background: transparent;
    position: fixed;
    z-index: 99;
    // background: rgb(0, 0, 0);
    // background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.3477766106442577) 5%, rgba(0, 212, 255, 0) 100%);
    // background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.3477766106442577) 5%, rgba(0, 212, 255, 0) 100%);
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.3477766106442577) 5%, rgba(0, 212, 255, 0) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00d4ff", GradientType=1);

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        list-style: none;
        height: 50px;
        margin: 0;
        padding: 0px 10px;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        background: $background-color;
        position: relative;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .nav-link {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                padding: 0 30px;
                border-radius: 50px;
                background: transparent;
                position: relative;
                &:hover {
                    background: $white-1;
                }
                &::after {
                    display: none;
                }
            }
        }
    }
}