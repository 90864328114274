.stats {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(10, 10, 10);
    position: absolute;
    .stats__wrapper {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        padding: 0;
        margin: 0;
        .wrapper__title {
            margin-top: 5%;
            padding: 20px;
            .title__h1 {
                font-size: 3em;
                color: $white-1;
            }
        }
        .wrapper__stats {
            margin-top: 10%;
            width: 100%;
            height: auto;
            padding: 10px;
            background: red;
            position: relative;
            .stats__card {
                width: 100%;
                background: green;
                min-height: 75px;
                .card__title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: blue;
                    .title__h2 {
                        text-align: center;
                        font-size: 2em;
                    }
                }
            }
        }
    }
}
