.profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #dcdcdc;
    width: 90%;
    border: 1px solid #dcdcdc;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    // background: rgb(25,25,25);
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../../assets/images/backgrounds/pexels-ben-mack-5708045.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
        background: rgb(25,25,25);
        padding:10px 20px;
        border-radius: 20px;
    }
    .info {
        background: rgb(25,25,25);
        padding:20px;
        border-radius: 20px;
        p {
            text-align: left;
        }
        span {
            color: $purple-5;
            margin-top: 30px;
            cursor: pointer;
            transition: 100ms ease all;
            &:hover {
                color: $purple-8;
            }
        }
    }
}