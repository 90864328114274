.contact {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    .contact__wrapper {
        display: flex;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        color: $white-1;
        width: 90%;
        height: auto;
        margin: 0;
        position: relative;
        .wrapper__title {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            height: 100%;
            padding: 20px;
            position: relative;
            .title__h1 {
                font-size: 3em;
            }
            .title__h2 {
                font-size: 2em;
            }
        }
        .wrapper__contact-info {
            display: flex;
            align-items: center;
            justify-content: top;
            flex-direction: column;
            padding: 20px;
            width: 30%;
            position: relative;
            .contact-info__section {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: auto;
                padding: 10px 20px;
                border-bottom: 1px solid gray;
                position: relative;
                transition: 100ms ease all;
                &:hover {
                    background: rgba(255,255,255,0.1);
                }
                &.notworking {
                    opacity: 0.2;
                    pointer-events: none;
                }
                .section__text {
                    font-size: 1.2em;
                    a {
                        cursor: pointer;
                        color: $white-1;
                        text-decoration: none;
                        position: relative;
                        &::after {
                            content: "";
                            width: 100%;
                            height: 1px;
                            left: 0;
                            bottom: 0;
                            transform: scaleX(0);
                            background: red;
                            transition: 100ms ease all;
                            position: absolute;
                        }
                        &:hover {
                            &::after {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }
}